html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
body{line-height:1}
ol,ul{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}
a{text-decoration:none!important}
html,body,.App{font-family:'Assistant',sans-serif;margin:0!important;padding:0!important;background:#1e1e1e}
html{scroll-behavior:smooth}
header {
    width: 100%;
    margin: 0 auto;
    float: left;
    padding: 0;
    background: #1e1e1e;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 110px;
    overflow: hidden;
}
header.scrolled {
    height: 75px;
    background: #18191a;
    padding-top: 5px;
    box-shadow:1px 1px 5px #111111;
}
header.scrolled .menu li a span:nth-child(3) {
    font-size: 10px;
    line-height: 10px;
}
header.scrolled .menu li a span:first-child {
    padding: 5px 0;
    font-size: 13px;
    width: 20px;
    clear: none;
    position: absolute;
    left: -7px;
    top: 0;
    line-height: 0px;
}
header.scrolled .menu li a span:nth-child(2) {
    font-size: 16px;
    line-height: 14px;
}
header.scrolled .logo{margin-top:10px}
header.scrolled .logo img{max-width:50px}
header.scrolled .logo h3{font-size:32px;line-height:34px}
header.scrolled .logo p {
    font-size: 12px;
}

header.scrolled .menu{margin:15px 0 0 0}
header.scrolled .menu li a:after, header.scrolled .menu li .active:after {
    left: -23px;
    top: -37px;
}
header .container{position:relative;min-height:80px;max-width:1200px}
.container{width:90%;margin:auto;padding:0;max-width:1250px}
header a,header a:hover,header a:focus{text-decoration:none;color:#ffffff}
header p:hover,header p:focus{opacity:1}
.wrapper{width:100%;float:left;clear:both;margin-top:110px}

.avatar {
    width: 100%;
    margin: 0 auto;
    padding: 225px 0;
    float: left;
    text-align: left;
    position: relative;
    clear: both;
    background: #18191a url(/static/media/siddharth-naidu.9974fdfa.png)no-repeat top right;
    overflow: hidden;
    background-size: contain;
}

.avatar img {
  display: none;
}

.avatar h1 {
    font-size: 34px;
    line-height: 38px;
    padding: 0;
    margin: 0 0 10px;
    color: #dddddd;
    z-index: 9999;
    position: relative;
    font-weight: 400;
    letter-spacing: -0.8px;
}

.avatar .react-typewriter-text-wrap {
    width: auto;
    display: inline-block;
    margin: 0 0 0 7px;
}

.avatar .react-typewriter-text-wrap h1 {
    font-family: 'Roboto Serif', serif;
    font-weight: 500;
}

.avatar h1 .emoji,.aboutme .emoji{animation-name:wave-animation;animation-duration:2.2s;animation-iteration-count:infinite;-ms-transform-origin:70% 70%;transform-origin:70% 70%;display:inline-block}
@keyframes wave-animation {
0%{transform:rotate(0.0deg)}
10%{transform:rotate(-15.0deg)}
20%{transform:rotate(17.0deg)}
30%{transform:rotate(-15.0deg)}
40%{transform:rotate(9.0deg)}
50%{transform:rotate(0.0deg)}
100%{transform:rotate(0.0deg)}
}
.avatar h2 {
    font-family: 'Roboto Serif', serif;
    font-weight: 700;
    font-size: 56px;
    line-height: 58px;
    max-width: 650px;
    padding: 0;
    margin: 15px 0 20px;
    color: #ffffff;
    z-index: 9999;
    position: relative;
}

.avatar p {
    max-width: 530px;
    font-size: 22px;
    line-height: 28px;
    color: #dddddd;
    margin-bottom: 40px;
    z-index: 9999;
    position: relative;
}

.btn {
    font-size: 15px;
    line-height: 15px;
    padding: 12px 35px;
    text-transform: uppercase;
}

.avatar .btn, .portfoliolist .btn, .aboutme .btn, .portfolio .btn, .contact .btn {color:#dddddd!important;border:2px solid #dddddd!important;transition:.3s all ease-in-out;z-index:99;position:relative;text-decoration:none!important;}

.avatar .btn:before, .avatar .btn:before, .portfoliolist .btn:before, .aboutme .btn:before, .portfolio .btn:before, .contact .btn:before {position:absolute;content:'';top:0;left:0;height:100%;width:0;background:#ff374a;transition:.3s all ease-in-out;z-index:-9}

.avatar .btn:hover:before, .portfoliolist .btn:hover:before, .aboutme .btn:hover:before, .portfolio .btn:hover:before, .contact .btn:hover:before {width:100%}

.avatar a:hover, .portfoliolist a:hover, .aboutme a:hover, .portfolio a:hover, .contact .btn:hover {border-color: #c02e2e!important;color:#fff!important;text-decoration:none!important;}


.avatar div{z-index:99;position:relative}
.avatar .container{max-width:1200px}
.logo{float:left;width:auto;min-width:280px;margin-top:25px;transition:.5s all ease-in-out}
.logo img{float:left;max-width:60px;margin-right:10px;transition:.5s all ease-in-out}
.logo h3 {
    font-family: 'Roboto Serif', serif;
    font-size: 38px;
    line-height: 40px;
    font-weight: 600;
    margin: 5px 0 0 0;
}

.logo p{font-size:15px;color:#999999}
.logo h3 span,.avatar h1 span,.project-info a,.project-info a:hover,.project-info a:focus{color:#ff374a}
.menu{float:right;width:auto;position:relative;margin:23px 0 0}
.menu ul{float:right;margin:0}
.menu li{float:left;margin:0;text-align:center;position:relative}
.menu li a{padding:0 15px;transition:.5s all ease-in-out;display:block}
.menu li a span{clear:both;width:100%;text-align:left;display:block;transition:.3s all ease}
.menu li a span:first-child{padding-bottom:5px;font-size:13px;color:#cccccc}
.menu li a span:nth-child(2) {
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    padding-bottom: 5px;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: -.4px;
    font-family: 'Roboto Serif', serif;
}

.menu li a span:nth-child(3){font-size:12px;font-weight:400;max-width:130px;line-height:13px;color:#999999}
.menu li a:hover,.menu li a:focus,.menu li .active,.social-icons li a:hover,.project a:hover,.article a:hover{color:#fff}
.menu li a:after{position:absolute;content:'.';color:#ff374a;left:13px;font-size:52px;top:-50px;opacity:0;transition:.3s all ease}
.menu li .active:after,.menu li:hover a:after{opacity:1}
.menu ul li ul,.menu ul li ul li{position:absolute;left:0;display:none}
.menu ul li ul span{display:none}
.menu ul li ul li a{padding:0}
.navBtn{display:none;width:33px;margin:43px 0 0;float:right}
.navBtn:focus,.navBtn button:focus{outline:0!important;box-shadow:none!important}
.navBtn button{background-color:transparent!important;border-color:transparent!important;padding:0;width:auto;margin:0}
.navBtn button:after{display:none}
.navBtn span{width:30px;height:2px;background:#000;margin-bottom:5px;clear:both;float:left}
.navBtn:hover .navBtn span{background:#ff374a}
.btn p{font-size:16px;color:#fff}
.avatar p i,.btn p i{font-weight:300;font-size:10px;border:1px solid #fff;padding:0;border-radius:50%;margin-left:5px;width:30px;height:30px;line-height:30px;text-align:center}
.briefIntro{float:left;width:100%;clear:both;text-align:center;padding:75px 0 65px;position:relative}
.briefIntro h2,.offer h2,.portfolio h2,.skills h2,.experience h2,.education h2,.hobbies h2,.facts h2,.portfoliolist h2{font-family: 'Roboto Serif', serif;font-weight:700;font-size:34px;color:#ffffff;margin-bottom:5px;text-align:center;line-height:56px}

.briefIntro p {
    font-size: 26px;
    line-height: 36px;
    max-width: 650px;
    color: #999999;
    margin: 0 auto;
}

.briefIntro:after,.experience:after,.offer:after,.portfolio:after,.project-info:after,.projectlist:after,.slider:after{content:"";width:100%;max-width:1000px;height:1px;left:0;right:0;margin-left:auto;margin-right:auto;bottom:0;position:absolute;z-index:-2;background:linear-gradient(90deg,#202020 0,#333333 35%,#333333 65%,#202020);background:-moz-linear-gradient(left,#202020 0,#333333 35%,#333333 65%,#202020 100%);background:-webkit-gradient(linear,left top,right top,color-stop(0,#202020),color-stop(35%,#333333),color-stop(65%,#333333),color-stop(100%,#222222))}
.gallery{float:left;width:100%;margin:5px auto 0}
.glance .gallery div{width:12.1%;float:left;margin:2px;overflow:hidden}
.gallery img{width:100%;float:left;transition:.3s all}
.briefIntro .btn,.portfoliolist .btn,.aboutme .btn,.portfolio .btn,.contact .btn{color:#000;border:2px solid #000;transition:.2s all ease-in}
.gallery:hover {cursor: pointer;}

.offer{float:left;width:100%;clear:both;text-align:center;padding:75px 0 60px;position:relative}
.designer,.developer{width:33.333%;float:left}
.offer img{margin:10px auto 15px;width:auto;max-width:70px}
.offer h3 {
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 600;
}
.offer p {
    font-size: 17px;
    line-height: 25px;
    margin: 0 auto 15px;
    color: #999999;
    max-width: 350px;
}
.mySkills{float:left;width:100%;clear:both;text-align:center;padding:10px 0 0;position:relative}
.mySkills div{width:16.6%;text-align:center;float:left}

.mySkills h3 {
    width: 150px;
    height: 150px;
    background: #090909;
    border: 10px solid #232323;
    color: #fff;
    line-height: 145px;
    border-radius: 50%;
    text-align: center;
    font-size: 62px;
    margin: 0 auto 10px;
    font-weight: 900;
}

.mySkills p {
    width: 90%;
    font-size: 18px;
    margin: 15px auto 25px;
    color: #999999;
    font-weight: 500;
}
.skills h4{font-size:22px;color:#666;width:65%;line-height:28px;margin:0 auto 15px}
.glance{padding-bottom:85px}
.portfolio{float:left;width:100%;margin:10px auto 0;clear:both;padding:65px 0 75px;position:relative;text-align:center}
.portfolio h2{margin-bottom:20px}
.portfolio figure,.portfolio figure img{border-radius:5px}
.portfolio,.portfolio figure,footer,.aboutMe,.skills,.work{float:left;width:100%;overflow:hidden;clear:both}
.work{padding:10px 0 0!important}
.project{position:relative;transition:1s all ease;float:left;width:33.333%;margin:0 0 30px;min-height:360px}
.project figure{width:98%;float:none;margin:auto;padding:5px 2.5%;border-radius:3px;overflow:hidden}
.portfolio figcaption{margin:5px 0 0 52px;text-align:left}
.project img{width:100%;transition:.3s all ease;text-align:center;margin:auto}
.project img:hover{-ms-transform:scale(1.05);transform:scale(1.05)}
.project p{font-size:18px;color:#666}
.project h3{font-size:32px;line-height:32px;margin:5px auto;color:#222}
.project a{color:#000}

.project a:hover, .project a:focus {color:#ff374a!important}

.banner{float:left;width:100%;clear:both;height:300px;position:relative;overflow:hidden;text-align:center;margin-bottom:50px;z-index:99}
.banner:before{position:absolute;content:'';width:100%;height:100%;top:0;left:0;background:rgba(0,0,0,0.77);z-index:9}
.quote:before{position:absolute;content:'';width:100%;height:100%;top:0;left:0;background:rgba(0,0,0,0.88);z-index:9}
.banner img{width:100%;position:absolute;left:0;top:-40%;z-index:-9}
.banner h3 {
    font-family: 'Roboto Serif', serif;
    font-size: 42px;
    line-height: 58px;
    margin-top: 100px;
    color: #fff;
    text-transform: uppercase;
    z-index: 999;
    position: inherit;
    font-weight: 700;
}
.banner h4{font-size:18px;color:#fff;line-height:22px;margin:5px 0 0;z-index:99;position:inherit}
.aboutme,.sitemap{float:left;width:100%;clear:both;text-align:center;padding:10px 0 50px}
.aboutme figure{width:350px;height:350px;border-radius:50%;margin:auto;margin-bottom:25px;overflow:hidden;box-shadow:0px 5px 15px 0 #121213}
.aboutme img{width:100%;border-radius:50%;transition:.3s all ease-in-out}
.aboutme figure:hover img,.dish img:hover{-ms-transform:scale(1.1);transform:scale(1.1)}
.trip figure:hover img{-ms-transform:scale(1.05);transform:scale(1.05)}
.aboutme h2 {
    font-family: 'Roboto Serif', serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 58px;
    padding: 0;
    margin: 0 auto 15px;
    letter-spacing: -1px;
    color: #ffffff;
}
.aboutme h2 span {
    color: #ff374a;
}
.aboutme .react-typewriter-text-wrap {
    width: auto;
    display: inline-block;
    margin: 0 0 0 13px;
}
.aboutme p {
    max-width: 600px;
    font-size: 24px;
    line-height: 32px;
    color: #cccccc;
    margin: 0 auto 40px;
}
.about-gallery{float:left;width:100%;clear:both;text-align:center;padding:30px 0 0}
.about-gallery div{width:16.6666%;float:left;margin:0;overflow:hidden}
.about-gallery div:hover img,.gallery a:hover img{-ms-transform:scale(1.2);transform:scale(1.2);cursor:pointer;}
.about-gallery img{width:100%;float:left;margin:0;transition:.3s all}
.quote{float:left;width:100%;clear:both;margin:-50px auto 0;padding:120px 0 70px;text-align:center;height:auto;position:relative;overflow:hidden}
.quote h2 {
    font-family: 'Roboto Serif', serif;
    font-size: 42px;
    line-height: 44px;
    padding: 0;
    margin: 0 auto 5px;
    color: #fff;
    z-index: 9;
    position: relative;
    font-weight: 700;
}
.quote h3 {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    margin: 0 auto 30px;
    z-index: 9;
    position: relative;
    font-weight: 600;
}
.quote p {
    max-width: 750px;
    font-size: 24px;
    line-height: 34px;
    color: #dddddd;
    margin: 0 auto 30px;
    z-index: 9;
    position: relative;
}
.quote img{position:absolute;top:0;right:0;width:100%;height:100%;z-index:1}
.quote:before{z-index:2!important}
.experience{float:left;width:100%;clear:both;margin:0 auto 10px;padding:80px 0 40px;position:relative}
.experience .container,.education .container{max-width:900px}
.experience h2,.education h2{text-align:left;margin-bottom:20px;z-index:99;position:relative}
.employer{width:40%;float:left}
.workInfo{width:60%;float:left;margin-bottom:50px}
.experience h3 {
    font-weight: 600;
    font-size: 26px;
    color: #dddddd;
}
.experience h4 {
    font-family: 'Roboto Serif', serif;
    font-size: 26px;
    color: #f0f0f0;
    margin-bottom: 7px;
    font-weight: 600;
}
.experience h5{font-size:16px;color:#999999;margin-bottom:10px}
.experience p{font-size:18px;color:#888888;line-height:26px;max-width:500px}
.education{float:left;width:100%;clear:both;margin:0 auto;padding:80px 0 50px;position:relative;overflow:hidden;background:#f0f0f0}
.education img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 0;
    height: 100%;
}
.education h4 {
    font-weight: 600;
    font-size: 24px;
    color: #f0f0f0;
    margin-bottom: 5px;
    line-height: 26px;
}
.education h5{font-size:15px;color:#999999;margin-bottom:10px}
.education p{font-size:16px;color:#888888;line-height:22px;max-width:500px;margin-bottom:5px}
.education a{color:#999;transition:.3s all ease;font-size:14px}
.education a:hover,.education a:focus{color:#ff374a}
.eduinfo{position:relative;clear:both;margin-bottom:30px}
.hobbies{float:left;width:100%;clear:both;padding:60px 0 30px;text-align:center}
.hobbies h2{margin-bottom:25px}
.hobbie{width:50%;float:left;margin-bottom:50px;text-align:left}
.hobbie figure{width:98%;border-radius:3px;float:left;overflow:hidden;margin:0 auto 20px;max-height:350px}
.hobbie:nth-child(3) figure,.hobbie:last-child figure{float:right}
.hobbie:nth-child(3) h3,.hobbie:nth-child(3) p,.hobbie:last-child h3,.hobbie:last-child p{width:90%}
.hobbie:nth-child(3) a.btn,.hobbie:last-child a.btn{margin-left:5%}
.hobbies img{width:100%;border-radius:3px;transition:.4s all ease}
.hobbie img:hover,.hobbie img:focus{-ms-transform:scale(1.1);transform:scale(1.1)}
.hobbie a.btn{color:#ffffff;font-size:16px;text-align:left;margin:0 auto 0 3%;width:95%;clear:both;text-transform:none;padding:0}

.hobbies h3 {
    font-family: 'Roboto Serif', serif;
    font-size: 28px;
    color: #ffffff;
    margin: 20px auto 10px;
    width: 95%;
    text-align: left;
    clear: both;
    font-weight: 700;
    letter-spacing: -.3px;
}
.hobbies p{font-size:18px;color:#999999;margin:12px auto;width:95%;text-align:left}
.facts{float:left;width:100%;clear:both;padding:80px 0 0;position:relative}
.facts .container{max-width:650px}
.facts h2{margin-bottom:130px}
.fact{width:100%;float:left;clear:both;margin-bottom:150px;position:relative}
.fact:after{position:absolute;content:'';width:1px;height:350px;background:#333333;left:50%;top:0;z-index:-9}
.facts h4{font-size:22px;color:#dddddd;line-height:26px;float:left;text-transform:uppercase;width:40%;margin:30px 10% 0 0;text-align:right}
.facts figure{width:100px;height:100px;border-radius:50%;text-align:center;clear:none;float:left;margin:0;position:absolute;left:42.5%;top:-80px;line-height:100px}
.facts img{margin:22.5px auto 0}
.facts p{font-size:18px;width:40%;color:#999999;line-height:26px;margin:30px 0 0 10%;float:left}
.sports figure{background:#a5aa11}
.beer figure{background:#d55339}
.photography figure{background:#2d5fb2}
.travelling figure{background:#9c54be}
.movies figure{background:#3f8c91}
.cooking figure{background:#e56969}
.smiley{position:absolute;bottom:0;z-index:99;left:0;right:0}
.smiley figure{background:#85b763;left:0;right:0;margin:auto}
.project h3, .trip h3, .post h3 {
    font-family: 'Roboto Serif', serif;
    font-size: 26px;
    line-height: 34px;
    margin: 10px auto 0;
    color: #ffffff;
    font-weight: 700;
    letter-spacing: -0.4px;
}
.project h4,.trip h4,.post h4{font-size:16px;line-height:28px;margin:0 auto 3px;color:#888}
.project p{font-size:16px;color:#666}
.project a,.offer a{color:#ffffff;font-size:16px}
.project a:hover,.project a:focus,.trip a:hover,.trip a:focus,.hobbie a:hover,.hobbie a:focus,.offer a:hover,.offer a:focus,.work a:hover,.work a:focus,.projectlist a:hover,.projectlist a:focus{color:#ff374a}
.project-info{padding-bottom:30px}
.project-info figure {
    text-align: center;
    margin: 30px auto 20px;
}
.project-details{width:70%;float:left;margin:30px 0}
.blog-info{width:100%;float:left;margin:30px 0}
.project-side{width:25%;float:left;margin:30px 0;padding-left:5%}
.project-info .container,.project-navigation .container{max-width:1100px}
.project-details h2, .blog-info h2 {
    font-family: 'Roboto Serif', serif;
    font-size: 42px;
    line-height: 56px;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 700;
}
.project-details h3, .blog-info h3 {
    font-size: 26px;
    line-height: 30px;
    color: #dddddd;
    margin-bottom: 15px;
    width: 90%;
    font-weight: 600;
    letter-spacing: -0.4px;
}
.project-details p{font-size:18px;line-height:26px;color:#777777;width:80%}
.blog-info p{font-size:18px;line-height:26px;color:#666;width:80%;margin-bottom:15px}
.blog-info a:hover li{color:#ff374a}
.blog-info ul{margin:0 0 20px 20px}
.blog-info li{font-size:18px;line-height:26px;color:#666;width:80%;list-style-type:disc}
.project-side h4 {
    font-size: 22px;
    line-height: 28px;
    color: #dddddd;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 900;
}
.project-side p{font-size:18px;line-height:26px;color:#888;margin-bottom:25px}
.project-navigation{margin:60px 0}
.prev{text-align:left;width:40%;float:left;padding-left:10%;position:relative}
.prev i{font-size:62px;color:#ffffff;position:absolute;left:0;top:0}
.project-navigation h4 {
    font-size: 30px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 900;
}
.project-navigation p{font-size:18px;line-height:26px;color:#999999}
.project-navigation a:hover h4{color:#ff374a}
.next{text-align:right;width:40%;float:right;padding-right:10%;position:relative}
.next i{font-size:62px;color:#ffffff;position:absolute;right:0;top:0}
.photos,.project-info,.project-info figure,.project-navigation,.blog{width:100%;float:left;clear:both;position:relative}
.photos,.blog{margin-bottom:10px}
.post{float:left;margin:.5%;width:49%;clear:none}
.photos .full-width{float:left;width:98%;clear:both}
.mainWrapper{width:100%;float:left}
.sidebar{width:20%;float:left;margin:0 3%;background:#f9f9f9;padding:20px 2%;border-radius:2px}
.sidebar h3{font-size:26px;color:#333;line-height:32px;margin-bottom:3px;clear:both}
.tripGallery h4{font-size:16px;color:#666;margin-bottom:15px}
.sidebar h3{font-size:28px;color:#333;line-height:32px;margin-bottom:10px;clear:both}
.sidebar p{font-size:16px;color:#666;line-height:22px;margin-bottom:25px;width:95%}
.sidebar img{max-width:225px;padding:5px;border:1px solid #f0f0f0;border-radius:50%;margin-bottom:10px!important}
.sidebar .gallery img{width:31%;border-radius:0;padding:0;border:0;clear:none;margin:2px!important}
.photos img{float:left;margin:1%;width:48%}
.photos .mainWrapper img{border-radius:3px;box-shadow:1px 1px 10px 0 #101010}
.sidebar .gallery,.sidebar iframe{margin-bottom:30px}
.sidebar .post,.sidebar .trip,.avatarimg{width:100%!important;clear:both;margin-bottom:15px;min-height:auto}
.sidebar .post iframe{min-height:150px;margin-bottom:10px}
.sidebar .post figcaption{margin:5px 0 0;text-align:left}
.sidebar .post h3{margin:0 0 3px;font-size:20px;line-height:20px}
.sidebar .post h5{margin:0 0 3px;max-width:500px;line-height:30px}
.post iframe{min-height:250px;margin-bottom:15px}
.post{margin-bottom:30px;min-height:370px}
.post h3,.post h5{margin:0 0 3px 25px;max-width:500px;line-height:30px}
.trip{width:31%;float:left;margin:0 1% 150px;position:relative}
.trip.full{width:54%}
.trip.half{width:41.5%}
.trip figure{width:100%;float:left;overflow:hidden;border-radius:3px;z-index:-9}
.trip img{width:100%;border-radius:5px;transition:.3s all ease;max-width:none;margin:0}
.trip figcaption {
    width: auto;
    height: auto;
    margin: 0 0 0 30px;
    background: #202020;
    clear: both;
    float: left;
    padding: 15px 20px;
    box-shadow: 1px 5px 5px 0 #111111;
    z-index: 99;
    position: absolute;
    bottom: -85px;
    border-radius: 3px;
}
.trip figcaption h3 {
    font-size: 22px;
    margin: 0;
    line-height: 26px;
}
.trip figcaption h4 {
    font-size: 14px;
    margin: 0;
    line-height: 26px;
}
.trip a{color:#ffffff;font-size:14px}

.trip.tall figure {
    height: 823px;
}

.trip.tall img {
    width: auto;
    height: 100%;
    float: right;
}

.pagination{width:100%;float:left;clear:both;text-align:center;margin:0 auto 50px}
.pagination li{display:inline-block;margin:5px 10px;font-size:18px;line-height:55px;width:50px;height:50px;background:#ddd;border-radius:50%;padding:0;transition:.2s all ease}
.pagination a{color:#333}
.pagination li:hover,.pagination li.active{background:#000;color:#fff;cursor:pointer}
.contact-me{clear:both;width:100%;padding:10px 0 70px}
.contact{width:70%;float:left;padding-top:30px}
.contactAvatar{width:30%;float:left;margin-bottom:50px}
.contactAvatar img{width:350px;height:350px;border-radius:50%;float:right}
.contact-me h2, .error h2 {
    font-family: 'Roboto Serif', serif;
    font-size: 38px;
    line-height: 46px;
    color: #ffffff;
    margin-bottom: 15px;
    font-weight: 700;
}
.contact-me .react-typewriter-text-wrap {
    display: inline-block;
    margin: 0 10px 0 15px;
    color: #ff374a;
}
.contact-me .social-icons{margin:5px 0 45px;text-align:left;float:left;width:100%}
.contact-me p a{color:#ffffff;font-size:22px;line-height:34px}
.social-icons ul{margin:20px 0 35px -10px}
.contact-me .social-icons li{margin:0 13px}
.contact-me .social-icons li a{color:#f0f0f0;font-size:20px}
.contact-me a,.contact-me a:hover,.contact-me a:focus,.social-icons a:hover i,.social-icons a:focus i{color:#ff374a;opacity:1}
.contact-me h3 {
    font-family: 'Roboto Serif', serif;
    font-size: 36px;
    margin: 35px auto 20px!important;
    color: #ffffff;
    clear: both;
    text-align: center;
    font-weight: 700;
}
.contact-me i{margin-right:5px}
.contact-me iframe {
    filter: invert(90%);
}
.social-icons i{margin-right:0}
.social-icons{text-align:center}
.social-icons li{float:left;margin:0 10px}
.social-icons p{font-size:18px;line-height:26px;color:#fff;margin-bottom:35px}
.tvshow{width:100%;float:left;clear:both;position:relative;margin-bottom:35px}
.tvshow img,.tvshow figure{width:100%;margin:0;float:left}
.tvshow figcaption{position:absolute;left:0;bottom:0;width:auto;padding:30px;background:#000;max-width:500px}
.tvshow h3{font-size:38px;text-transform:uppercase;margin-bottom:5px;color:#fff}
.tvshow h4{font-size:22px;margin-bottom:10px;color:#fff;opacity:.9}
.tvshow p{font-size:16px;margin-bottom:0;color:#fff;line-height:20px;opacity:.7}
.dish{width:48%;float:left;position:relative;margin:0 1% 20px;overflow:hidden;max-height:500px}
.dish img,.dish figure{width:100%;margin:0;transition:.5s all ease}
.dish figcaption{position:absolute;left:0;bottom:0;width:100%;padding:25px 0 20px;background:rgba(0,0,0,0.7);text-align:center}
.dish h3 {
    font-size: 26px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
}
footer{background:#111111;position:relative;padding:70px 0 50px;float:left;width:100%;clear:both;text-align:center;color:#fff}
footer .container>div{float:left;width:33.333%}
footer .logo{margin:0}
footer .logo p {color:#fff}
footer .logo a{width:280px;float:left;text-align:left;color:#fff;margin-bottom:15px}
footer h4 {
    font-family: 'Roboto Serif', serif;
    font-size: 20px;
    text-transform: capitalize;
    clear: both;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 600;
}
footer h4 a,footer h4 a:hover,footer h4 a:focus{color:#fff}
footer .portfolioTitle{float:left;clear:both}
footer li{list-style-type:disc;margin:0 0 0 20px;width:40%;float:left;padding:0;line-height:15px;clear:none;font-size:15px;text-align:left}
footer .logo li a,footer li a{width:auto;float:left;text-align:left;color:#fff;margin-bottom:10px}
footer .navigation{margin-bottom:15px;float:left}
.avatar .btn i,.portfolio .btn i,.aboutme .btn i,.contactme a i{margin-right:6px}
footer nav{margin-bottom:15px}
footer nav li,.social-icons li{list-style-type:none;display:inline-block;margin:0 10px;text-transform:uppercase;font-size:15px}
footer nav li a{color:#ccc!important}
footer nav li a:hover,footer nav li a:focus{color:#fff!important}
footer .links div{width:45%;float:left;padding-left:5%}
footer .links ul{width:100%;float:left;margin-bottom:10px}
footer .links div li{width:100%;float:left}
.contactme{text-align:left;clear:both}
.contactme p{font-size:17px;line-height:22px;margin:12px 0 5px;width:80%;color:#fff}
.contactme .btn {
    width: auto!important;
    font-size: 15px;
    line-height: 26px;
    padding: 8px 15px;
    background: #222;
    transition: all .3s ease-in;
    color: #fff;
    margin-bottom: 10px!important;
    margin-right: 5px;
    z-index: 1;
    position: relative;
    text-transform: none;
}
.contactme a:hover,.contactme a:focus{background:#ff374a}

.contactme .btn:before {position:absolute;content:'';top:0;left:0;height:100%;width:0;background:#ff374a;transition:.3s all ease-in-out;z-index:-9}

.contactme .btn:hover:before {width:100%}

.contactme a:hover {color:#fff!important}


.social-icons{margin:0 80px}
footer .social-icons{margin:15px 0 0;float:left}
.social-icons li{font-size:14px;margin:0 15px 0 0;float:left;width:auto;line-height:18px;text-transform:capitalize}
.social-icons li a{padding:0;background:none;font-size:15px}
.extralinks .gallery div {width:19%;float:left;margin:2px;overflow:hidden}
.extralinks img{width:100%;margin:0}
.copyright{background:#000;padding:20px 0 15px;float:left;width:100%;clear:both;text-align:center;color:#fff;margin-top:0;font-size:14px}
.madewith{float:left}
.madewith a:hover, .madewith a:focus, .goTop a:hover, .goTop a:focus {color:#ff374a!important}
.goTop{float:right}
.views{float:left;font-size:14px;font-weight:500;line-height:0;color:#fff;margin-right:10px;width:auto;border-right:1px solid #ccc;padding-right:10px}
.views p{float:left!important;margin:8px 0 0}
.views img{height:15px;float:right;filter:grayscale(100%);margin:-1px 0 0 5px}
.goTop p{float:right;border-left:1px solid #fff;padding-left:15px}
.goTop a,.copyright a{color:#fff!important}
.mobilemenu{position:absolute;right:3px;top:70px;background:#ff374a;z-index:999;padding:10px 35px 5px 25px}
.mobilemenu li{margin:12px 0}
.mobilemenu li a{font-size:20px;color:#fff}
.music{display:none}
.sitemap h4,.projectslinks h4,.projectslist h4,.projectslist h4 a{font-size:20px;clear:both;text-align:left;margin-bottom:10px;color:#ffffff}
.sitemap .links{float:left;width:50%}
.sitemap ul{float:left;width:100%;clear:both;margin-bottom:30px}
.sitemap li,.projectslist li{list-style-type:disc;margin:6px 0 6px 15px;width:100%;float:left;padding:0;line-height:16px;clear:none;font-size:16px;text-align:left;color:#555}
.projectslinks li a,.sitemap li,.sitemap li a,.projectlist li a,.projectslist li a{color:#dddddd}
.sitemap li a:hover,.sitemap li a:focus,.projectslinks li a:hover,.projectslinks li a:focus,.projectlist li a:hover,.projectlist li a:focus{color:#ff374a}
.projectslinks{width:50%;margin-top:50px;float:left}
.sitemap li{width:45%;max-width:250px}
.followme li{list-style-type:none;margin-left:0}
.followme li i{margin-right:5px}
.websites{padding:0}
.projectlist{float:left;width:100%;clear:both;margin-bottom:80px;padding-bottom:30px;position:relative}
.projectlist figure{float:left;width:60%}
.projectlist figcaption{float:left;width:40%}
.projectlist figcaption div{float:left;width:100%;clear:both;margin-bottom:20px}
.projectlist figcaption ul{float:left;width:100%}
.projectlist li{list-style-type:disc;margin:5px 0 8px 20px;width:40%;float:left;padding:0;line-height:16px;clear:none;font-size:16px;text-align:left;color:#555}
.projectlist h3{font-size:20px;color:#333;margin-bottom:10px}
.projectlist a{clear:both;float:left;color:#000}
.projectlist h2,.slider h2{font-size:28px;color:#111;margin-bottom:15px}
.projectlist:last-child{margin-bottom:0}
.projectslist{width:50%;float:left}
.projectslist ul{margin-bottom:30px;float:left;width:100%}
.projectslist li{float:left;width:45%;max-width:180px}
.projectslist.twentytwenty li{width:100%;max-width:none}
.clear{clear:both}
header .container div:last-child,.bm-burger-button span{display:none}
.bm-menu-wrap{z-index:99999!important;width:165px!important;height:100px!important;transition:all .3s ease 0!important}
.bm-menu{margin:75px 20px 0 0 !important;z-index:999!important;background:#ff374a!important;padding:15px 20px 0;height:auto!important;width:auto!important}
.bm-menu a{color:#fff!important;margin-bottom:10px;font-size:18px}
.bm-burger-button button{left:auto!important;width:100px!important;height:100px!important;right:-20px!important;z-index:9999!important}
.bm-cross-button{left:auto;width:90px!important;height:100px!important;right:0!important;z-index:9999!important;top:0!important}
.bm-menu a,.bm-menu a:hover,.bm-menu a:focus{outline:0!important}

header.scrolled .bm-cross-button {
    height: 75px!important;
    top: -5px!important;
}

.portfolioMain{float:left;width:100%;clear:both;text-align:left;padding:0 0 25px}
.graphic img{padding:0;border:1px solid #ddd;width:47.5%}
.error{text-align:center;padding:50px 0 100px;clear:both}
.react-tabs{text-align:center}
.react-tabs__tab-list{border-bottom:0!important;margin:0 0 40px!important;padding:0!important;width:27%;float:left}
.react-tabs__tab--selected,.react-tabs__tab:hover,.react-tabs__tab:focus{background:#ff374a!important;border:0!important;color:#fff!important}

.react-tabs__tab {
    display: block!important;
    border: 0;
    border-bottom: none;
    bottom: 0;
    position: relative;
    list-style: none;
    padding: 13px 0 13px 50px!important;
    cursor: pointer;
    width: 230px;
    text-align: left;
    border-radius: 25px!important;
    background: none;
    color: #ffffff;
    margin: 0 0 5px 0;
    font-size: 17px;
    line-height: 18px;
    clear: both;
    transition: .1s all ease;
    position: relative;
}

.react-tabs__tab i {
    position: absolute;
    left: 7%;
    top: 12px;
    font-size: 18px;
    margin-right: 10px;
}
.react-tabs__tab i.fa.fa-bookmark{left:9%}

.tabs-fixed ul {
    position: fixed;
    top: 100px;
    left: auto;
}

.react-tabs__tab-panel{width:73%;float:right}
.react-tabs__tab:focus:after{display:none}
.portfolioMain .project{position:relative;transition:1s all ease;float:left;width:48%;margin:0 1% 30px;min-height:280px}
.portfolioMain .project figcaption{margin:15px 0 0 60px;text-align:left}
.portfolioMain .project h3 {
    font-size: 28px;
    line-height: 30px;
    margin: 0 auto;
    color: #ffffff;
    letter-spacing: -0.4px;
}
.portfolioMain .project h4{font-size:14px;line-height:28px;margin:0 auto;color:#888}
.something{font-size:24px;line-height:26px;margin:0 auto;color:#222;text-align:center}
.bm-overlay{background:transparent!important;top:0;left:0;display:block;z-index:99999!important}
.bm-burger-button button{outline:0!important}

.project-info a:hover, .project-info a:focus, .contact-me a:hover, .contact-me a:focus {text-decoration: underline!important;}

footer li:hover, footer li:focus, footer li a:hover, footer li a:focus, footer h4 a:hover, footer h4 a:focus {color:#ff374a}

::-moz-selection{color:#fff;background:#ff374a}
::selection{color:#fff;background:#ff374a}

.hidden {
    display: none;
}

@media all and (max-width:2600px) {
.avatar{background-position-x:100%}
}
@media all and (max-width:1500px) {
.avatar{padding:130px 0}
.avatar h2{font-size:52px;line-height:48px}
}
@media all and (max-width:1280px) {
.menu li a{padding:0 15px}
.menu li a span:nth-child(3){font-size:11px;font-weight:400;max-width:120px;line-height:12px;color:#888}
.avatar h2{font-size:42px;line-height:38px;max-width:600px}
.avatar h1{font-size:30px;line-height:32px}
.avatar p{max-width:480px;font-size:22px;line-height:30px;color:#666;margin-bottom:40px;z-index:9999;position:relative}
.avatar{background-position-x:115%}
.mySkills h3 {
    width: 110px;
    height: 110px;
    background: #111;
    border: 7px solid #eee;
    color: #fff;
    line-height: 110px;
    border-radius: 50%;
    text-align: center;
    font-size: 42px;
    margin: 0 auto 5px;
}
footer h4{font-size:18px}
footer li{font-size:14px}
.social-icons li{margin:0 12px 0 0}
.banner{height:200px}
.banner h3{font-size:42px;line-height:42px;margin-top:70px}
.mySkills p{width:90%;font-size:14px;margin-bottom:20px}
.education h4{font-size:22px}
.project{margin:0 0 50px;min-height:280px}
.menu li .active:after{position:absolute;content:'.';color:#ff374a;left:0;font-size:52px;top:-22px}
.project-info img{width:100%}
.project-details h2,.blog-info h2{font-size:38px;line-height:46px;margin-bottom:5px}
.trip{width:48%;margin:0 1% 150px}
.post iframe{min-height:300px;margin-bottom:15px}
.post{float:left;margin:1%;width:98%;clear:none;margin-bottom:30px;min-height:400px}
.contact-me h2,.error h2{font-size:40px;line-height:46px;color:#ffffff;margin-bottom:10px;width:90%}
}
@media all and (max-width:900px) and (min-width:600px) {
.avatar{text-align:left;padding:130px 0 0;height:450px;background-size:cover;background-position-x:60%}
.avatar:after{position:absolute;content:"";top:0;left:0;width:100%;height:100%;z-index:0;background:rgba(0,0,0,0.8)}
.avatar h1{font-size:28px;line-height:32px;color:#fff}
.avatar h2{font-size:42px;line-height:44px;color:#fff}
.avatar p{max-width:450px;font-size:20px;line-height:26px;color:#fff;margin-bottom:30px}
.avatar .btn{color:#fff!important;border:2px solid #fff!important;transition:.2s all ease-in;z-index:9999;position:relative}
.mySkills div{width:33.3%;text-align:center;float:left}
.menu li a span:nth-child(3){display:none}
header .logo{float:left;width:auto!important;min-width:215px;margin:25px 0}
.menu li{float:left;margin:0 8px;text-align:center;position:relative}
.menu li a span:nth-child(2){font-size:16px}
.logo h3{font-size:30px;line-height:32px;margin-top:3px}
.logo img{float:left;max-width:50px;margin-right:15px}
.menu li a span:first-child{margin:8px 0 5px;font-size:13px;color:#555}
.mySkills h3{width:150px;height:150px;background:#111;border:7px solid #eee;color:#fff;line-height:160px;border-radius:50%;text-align:center;font-size:52px;margin:0 auto 5px}
.mySkills p{width:90%;font-size:18px;margin-bottom:20px}
.gallery img{width:16%;float:left;margin:2px}
.project{margin:0 0 50px;min-height:300px;width:48%}
.hobbies p{font-size:16px;color:#666;margin:0 auto 10px;width:85%;text-align:left;line-height:20px}
.hobbies h3{margin:10px auto 5px}
.logo,.links{width:50%!important;margin-bottom:30px!important}
.extralinks{width:100%!important;margin-bottom:60px!important}
.social-icons{margin:15px 0 25px -7px;float:none;clear:both}
footer .gallery img{width:16%;float:left;margin:2px;padding:0}
.copyright{margin-top:0}
.footer-inner nav li{margin:5px 10px}
.footer-inner .social-icons,.footer-inner .social-icons ul{margin:40px auto 50px}
footer{padding:50px 0 0}
.links{margin:10px 0 30px}
.madewith p{width:90%;margin:0 auto 10px;font-size:16px!important}
.madewith{margin:15px auto;float:none}
.goTop{float:none;max-width:340px;margin:0 auto}
.banner img{top:-10%}
.education{padding:80px 30px 50px}
.education img,.contactAvatar{display:none}
.experience{float:left;width:90%;clear:both;margin:0 auto 10px;padding:80px 30px 40px;position:relative}
.mainWrapper{width:100%}
.sidebar{display:none}
.contact-me .contact{padding:15px 10px 60px;width:100%}
.contact-me h2{font-size:42px;line-height:46px}
.contact-me p a{font-size:20px;line-height:32px}
.contact-me .social-icons li{margin:0 10px}
.contact-me h3{font-size:30px}
}
@media all and (max-width:480px) {
.menu,.tvshow figcaption h4,.tvshow figcaption p{display:none!important}
.tvshow figcaption{padding:15px 15px 5px}
.tvshow h3{font-size:22px}
header{height:100px}
header.scrolled .navBtn{margin-top:25px}
.wrapper{margin-top:100px;}
.mainWrapper{width:100%}
.navBtn span {
    background-color: #ffffff;
}
.sidebar{display:none}
.sitemap{padding-bottom:0!important}
.sitemap li{font-size:14px;margin-top:3px}
.portfolioMain{padding-top:15px!important}
.avatar:before{content:"";width:100%;max-width:1000px;height:1px;left:0;right:0;margin-left:auto;margin-right:auto;top:5px;position:absolute;z-index:0;background:linear-gradient(90deg,#fff 0,#eee 35%,#eee 65%,#fff);background:-moz-linear-gradient(left,#fff 0,#eee 35%,#eee 65%,#fff 100%);background:-webkit-gradient(linear,left top,right top,color-stop(0,#fff),color-stop(35%,#eee),color-stop(65%,#eee),color-stop(100%,#fff))}
.logo{margin:20px 0}
.project figure,.graphic img,.photos img,.post,.dish,.sitemap .links{width:100%;padding:0}
.sitemap li{font-size:14px}
.mySkills p{width:100%;font-size:16px}
.hobbies p{font-size:16px;line-height:19px}
.aboutme figure{width:250px;height:250px;margin:20px auto}
.aboutme h2{font-size:30px;line-height:38px}
.aboutme p{font-size:20px;line-height:28px}
.about-gallery img{width:100%}
.about-gallery div {
    width: 33.3333%;
}
.container{width:90%}
.project{width:100%;margin:0 0 35px;min-height:auto}
header .container div:last-child{display:block}
.navBtn{display:block;border-radius:0!important;transition: 1s all ease}
.dropdown-menu{position:absolute!important;right:0!important;top:5px!important;width:180px!important;background:#ff374a;padding:20px 30px 0!important;left:0!important;border-radius:0;border:0;box-shadow:none;float:right;margin-left:-145px}
.dropdown-menu li{margin-bottom:15px}
.dropdown-menu li a{font-size:22px;color:#fff}
.avatar {
    text-align: left;
    padding: 250px 0;
    height: 450px;
    background-size: cover;
    background-position-x: 50%;
}
.avatar:after{position:absolute;content:"";top:0;left:0;width:100%;height:100%;z-index:0;background:rgba(0,0,0,0.8)}
.avatar h1{font-size:28px;line-height:32px;color:#fff}
.avatar h2{font-size:36px;line-height:36px;color:#fff}
.avatar p{max-width:620px;font-size:20px;line-height:26px;color:#fff;margin-bottom:30px}
.avatar .btn{color:#fff!important;border:2px solid #fff!important;transition:.2s all ease-in;z-index:9999;position:relative}
.designer,.developer,.hobbie,.follow{width:100%;margin-bottom:30px}
.bm-cross{background:#ffffff;width:2px!important;height:25px!important}
.bm-cross-button{z-index:-9!important;background:#333333;transition:1s all ease}
.bm-cross-button>span{top:35px!important;right:45px!important}
header.scrolled .bm-menu{margin:70px 20px 0 0 !important}
header.scrolled .bm-cross-button>span{top:25px!important}
.mySkills div{width:50%}
.mySkills h3{width:120px;height:120px;line-height:115px;font-size:42px!important;border:5px solid #eee;margin:0 auto 5px}
.gallery a {
    width: 31%;
    margin: 1%;
}
.glance .gallery div {
    width: 48%;
}
.extralinks .gallery div {
    width: 32%;
}

.extralinks .react_lightgallery_item:last-child {
    display: none!important;
}

.gallery img{width:100%;float:left;margin:1%}
.follow{margin-bottom:60px}
.briefIntro,.offer,.portfolio,.hobbies,.copyright{padding:50px 0}
.copyright{margin-top:0}
.footer-inner nav li{margin:5px 10px}
.footer-inner .social-icons,.footer-inner .social-icons ul{margin:40px auto 50px}
footer{padding:50px 0 0}
.links{margin:10px 0 30px}
.madewith p{width:90%;margin:0 auto;font-size:16px;line-height:24px}
.madewith{margin-bottom:30px}
.goTop{float:left;margin:auto;width:100%}
.social-icons{margin:0 auto;float:none;width:100%;max-width:312px}
.goTop p{float:none;border-left:0;padding-left:0;clear:both;margin:30px 0 auto}
.react-tabs__tab-list{border-bottom:0!important;margin:0 0 30px!important;padding:0!important;width:100%;float:left}
.react-tabs__tab-panel{width:100%;float:left}
.portfolioMain .project{position:relative;transition:1s all ease;float:left;width:100%;margin:0 0 30px;min-height:auto}
.offer h3{font-size:20px}
.offer p{font-size:16px;width:90%;line-height:24px;margin:0 auto 10px;color:#888;max-width:470px}
.error h2{margin:10px auto;font-size:32px;line-height:32px}
.quote img{position:absolute;top:0;left:-110%;width:auto;height:100%}
.trip figcaption{margin:0 0 0 30px;bottom:-90px}
.views{font-size:13px;margin-right:3px;padding-right:3px}
.briefIntro h2,.offer h2,.portfolio h2,.skills h2,.experience h2,.education h2,.hobbies h2,.facts h2{font-size:30px;margin-bottom:15px}
.briefIntro p{font-size:20px;line-height:30px}
.banner{height:250px;margin-bottom:30px}
.banner img{left:-90px;width:250%;top:0}
.banner h3{font-size:32px;line-height:42px;margin-top:80px}

.banner h4{font-size:18px;line-height:20px;margin:5px auto 0;width:90%}
.banner p{font-size:18px;line-height:20px;margin:5px auto 0;color:#fff}
.facts{padding:60px 0 0}
.facts h4{width:95%;margin:30px auto 10px;text-align:center;font-size:20px;float:none}
.facts p{width:95%;margin:5px auto 0;text-align:center;font-size:16px;line-height:22px;float:none}
.facts figure{margin:0 auto;left:0;top:-90px;right:0}
.fact:after,.education img,.contact-me .contactAvatar{display:none}
.facts h2,.fact{margin-bottom:125px}
.quote{margin-bottom:0}
.quote h2{font-size:30px;line-height:32px}
.quote h3{font-size:18px;margin:0 auto 20px}
.quote p{max-width:95%;font-size:20px;line-height:28px;margin:0 auto}
.experience{padding:60px 0 20px}
.employer,.workInfo,.trip,.project-info img,.project-details,.project-side,.prev,.next,.post,.contact,.address,.trip.half,.trip.full{width:100%}
.trip.tall figure {
    height: 250px;
}
.experience h2,.education h2{margin-bottom:15px}
.experience h3,.experience h4{font-size:22px;margin-bottom:10px}
.workInfo{margin-bottom:30px}
.experience p{font-size:16px}
.experience h5{margin-bottom:10px}
.skills h4{font-size:18px;width:95%;line-height:26px;margin:0 auto 15px}
.education {
    margin: 0 auto;
    padding: 50px 0 0;
    background: transparent;
}
.education h4{font-size:20px}
.work{padding:10px 0 0}
.tabs-fixed ul {
    position: relative;
    top: 0;
    left: auto;
}
.project-details h2{font-size:32px;line-height:36px;color:#ffffff}
.project-details h3{font-size:20px;line-height:26px;width:100%}
.project-details p{font-size:16px;line-height:24px;color:#777;width:100%}
.project-side{margin:0;padding-left:0}
.project-navigation{margin:35px 0;float:left;width:100%;clear:both;overflow:hidden}
.project-navigation i{font-size:42px;top:5px}
.prev{width:80%;padding-left:20%;margin-bottom:30px}
.next{width:80%;padding-right:20%}
.post{min-height:auto}
.post iframe{min-height:250px}
.post h3{font-size:26px;line-height:32px;margin-bottom:10px}
.blog{margin-bottom:20px}
footer .container>div,.contactme p{width:100%}
footer .social-icons{margin:15px 0 25px -7px}
.react-tabs__tab{font-size:20px;line-height:20px;clear:both;float:none;margin:5px auto;display:block!important}
.websites{padding:0}
.contact-me .contact{padding:15px 10px 60px}
.contact-me h2{font-size:32px;line-height:36px;color:#ffffff;}
.contact-me p a{font-size:20px;line-height:32px}
.contact-me .social-icons li{margin:0 10px}
.contact-me h3{font-size:30px}
.contact-me .btn {
    clear: both;
    float: left;
}
.pagination{margin:0 auto}
.gallery a:last-child{display:none;}
}
@media all and (max-width:380px) {
.avatar h2{font-size:30px;line-height:32px}
.avatar h1{font-size:24px;line-height:28px;color:#fff}
.avatar p{max-width:620px;font-size:17px;line-height:24px;color:#fff;margin-bottom:30px}
.social-icons li{margin:0 5px}
.contact-me h2{font-size:30px;line-height:34px}
}
